import accessories from './accessories';
import alerts from './alerts';
import apikey from './apikey';
import assets from './assets';
import assetfields from './assetfields';
import assettypes from './assettypes';
import auditlog from './auditlog';
import auth from './auth';
import customfields from './customfields';
import events from './events';
import intune from './intune';
import jira from './jira';
import kandji from './kandji';
import locations from './locations';
import licenses from './licenses';
import loading from './loading';
import users from './users';
import utils from './utils';

export default {
  accessories,
  alerts,
  apikey,
  assets,
  assetfields,
  assettypes,
  auditlog,
  auth,
  customfields,
  events,
  intune,
  jira,
  kandji,
  locations,
  licenses,
  loading,
  users,
  utils,
};
